import { push } from 'connected-react-router';
import { SubmissionError } from 'redux-form';
import type { AddExternalAccountForm, Dispatch } from '../../utilities/types';
import type { AddExternalAccountService } from '../externalAccounts/externalAccounts.service';
import { addExternalAccountService } from '../externalAccounts/externalAccounts.service';
import { asyncValidateRoutingNumber } from '../validateRoutingNumber/validateRoutingNumber.services';
import {
  setFlashMessage,
  clearFlashMessage,
} from '../../components/flashMessage/flashMessage.reducer';
import { ACTION_ADD_EXTERNAL_ACCOUNT_INSTANT_VERIFICATION_SUCCESS } from '../verifyTrialDeposits/verifyTrialDeposits.reducer';
import {
  ACTION_ADD_EXTERNAL_ACCOUNT_REQUEST,
  ACTION_ADD_EXTERNAL_ACCOUNT_SUCCESS,
  ACTION_ADD_EXTERNAL_ACCOUNT_REJECTED,
  ACTION_VALIDATE_ROUTING_NUMBER_REJECTED,
  ACTION_VALIDATE_EXTERNAL_ACCOUNT_REQUEST,
  ACTION_SET_IS_INSTANTLY_VERIFIED,
  ACTION_SET_BANK_NAME,
} from './addExternalAccount.reducers';
import {
  FlashMessageText,
  FlashMessageVariant,
} from '../../components/flashMessage/flashMessage.constants';
import { encrypt } from '../../utilities/crypt';
import type { AccountFundingFlow } from '../../utilities/accountFundingFlowType';
import type { Path } from '../routes/routes.constants';
import Routes from '../routes/routes.constants';
import { EXTERNAL_ACCOUNT_FLOW } from './addExternalAccount.constants';
import { ACTION_CLEAR_ALL_ACCOUNTS } from '../allAccounts/allAccounts.reducer';

export const ExternalAccountTypes = Object.freeze({
  CHECKING: 'Checking',
  SAVINGS: 'Savings',
});

export type ExternalAccountType = typeof ExternalAccountTypes[keyof typeof ExternalAccountTypes];

type AddExternalAccountRoutes = {
  trialDepositInstructions: Path;
};

const fundingRoutes: AddExternalAccountRoutes = {
  trialDepositInstructions: Routes.TRIAL_DEPOSIT_INSTRUCTIONS,
};

const servicingRoutes: AddExternalAccountRoutes = {
  trialDepositInstructions: Routes.TRIAL_DEPOSITS,
};

type AddExternalAccountDispatchProps = {
  externalAccountInfo: AddExternalAccountForm;
  afFlow?: AccountFundingFlow;
  service?: AddExternalAccountService;
  onInstantlyVerified?: () => void;
};

export const validateExternalAccount =
  (externalAccountInfo: AddExternalAccountForm) => async (dispatch: Dispatch) => {
    dispatch({ type: ACTION_VALIDATE_EXTERNAL_ACCOUNT_REQUEST });
    try {
      await asyncValidateRoutingNumber(externalAccountInfo.routingNumber);
    } catch (e) {
      if (e && e.routingNumber) {
        dispatch({ type: ACTION_VALIDATE_ROUTING_NUMBER_REJECTED });
        throw new SubmissionError(e);
      } else {
        dispatch({
          type: ACTION_ADD_EXTERNAL_ACCOUNT_REJECTED,
          payload: FlashMessageText.GENERIC_ERROR,
        });
      }
      throw e;
    }
  };

const addExternalAccount =
  (
    externalAccountInfo: AddExternalAccountForm,
    routes: AddExternalAccountRoutes,
    service: AddExternalAccountService,
    afFlow?: AccountFundingFlow,
    onInstantlyVerified?: () => void
  ) =>
  async (dispatch: Dispatch) => {
    let response;
    dispatch({
      type: ACTION_ADD_EXTERNAL_ACCOUNT_REQUEST,
      payload: {
        accountType: externalAccountInfo.accountType,
        accountNumber: externalAccountInfo.accountNumber,
      },
    });
    try {
      response = await service({
        ...externalAccountInfo,
      });
    } catch (err) {
      const errorMessage =
        err && err.data && err.data.message ? err.data.message : FlashMessageText.GENERIC_ERROR;
      dispatch({ type: ACTION_ADD_EXTERNAL_ACCOUNT_REJECTED, payload: errorMessage });
      if (err.data.errorCode === EXTERNAL_ACCOUNT_FLOW.VERIFICATION_FAILED_ERROR) {
        const redirectPath = afFlow
          ? Routes.AO_FUNDING_ACCOUNT_VERIFICATION_FAILED
          : Routes.ACCOUNT_VERIFICATION_FAILED;
        dispatch(push(redirectPath));
      } else {
        dispatch(
          setFlashMessage({
            messageType: FlashMessageVariant.ERROR,
            messageText: errorMessage,
            'aria-live': 'assertive',
          })
        );
      }
      return;
    }
    dispatch({ type: ACTION_ADD_EXTERNAL_ACCOUNT_SUCCESS, payload: response.accountAdded });
    dispatch({ type: ACTION_CLEAR_ALL_ACCOUNTS });
    dispatch({ type: ACTION_SET_BANK_NAME, payload: response.bankName });
    if (response.accountAdded === true) {
      dispatch({
        type: ACTION_ADD_EXTERNAL_ACCOUNT_INSTANT_VERIFICATION_SUCCESS,
        payload: response,
      });

      if (onInstantlyVerified) {
        dispatch(clearFlashMessage()); // bandaid until BSL clears successMessage response object.
        dispatch({ type: ACTION_SET_IS_INSTANTLY_VERIFIED, payload: response.externalId });
        onInstantlyVerified();
      } else {
        dispatch(push(Routes.VERIFY_TRIAL_DEPOSITS));

        // still need this for dashboard
        dispatch(
          setFlashMessage({
            messageType: FlashMessageVariant.SUCCESS,
            messageText: FlashMessageText.VERIFY_TRIAL_DEPOSITS_SUCCESS,
          })
        );
      }
    } else {
      dispatch(push(Routes.ACCOUNT_VERIFICATION_FAILED));
    }
  };

export const addExternalAccountForFunding =
  ({
    externalAccountInfo,
    afFlow,
    onInstantlyVerified,
    service = addExternalAccountService,
  }: AddExternalAccountDispatchProps) =>
  (dispatch: Dispatch) =>
    addExternalAccount(
      externalAccountInfo,
      fundingRoutes,
      service,
      afFlow,
      onInstantlyVerified
    )(dispatch);

export const addExternalAccountForServicing =
  ({ externalAccountInfo, service = addExternalAccountService }: AddExternalAccountDispatchProps) =>
  (dispatch: Dispatch) =>
    addExternalAccount(externalAccountInfo, servicingRoutes, service)(dispatch);
