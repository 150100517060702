import React from 'react';
import { Grid } from '@material-ui/core';
import Button from 'bank-component-library/ui/atoms/Button';
import { StandardText, InformationalSectionHeaderBold } from '../typography/typography';
import i18n from '../../strings/i18n';

type TryAgainProps = {
  onNext?: () => void;
};

const TryAnotherAccount = (props: TryAgainProps) => {
  const { onNext } = props;
  return (
    <Grid className="mt-6 mb-4">
      <Grid>
        <InformationalSectionHeaderBold data-test="try-another-account">
          {i18n({ ACCOUNT_LINKED_ERROR: 'TRY_ANOTHER_ACCOUNT' })}
        </InformationalSectionHeaderBold>
      </Grid>

      <Grid className="mt-2 mb-4">
        <StandardText>{i18n({ ACCOUNT_LINKED_ERROR: 'TRY_ANOTHER_ROUTER' })}</StandardText>
      </Grid>

      <Button variant="primary-outlined" onClick={onNext} data-test="try-another-account-button">
        {i18n({ ACCOUNT_LINKED_ERROR: 'TRY_ANOTHER_ACCOUNT' })}
      </Button>
    </Grid>
  );
};

export default TryAnotherAccount;
