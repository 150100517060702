import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Grid, withStyles, Card, CardContent } from '@material-ui/core';
import { kebabCase } from 'lodash';
import Button from 'bank-component-library/ui/atoms/Button';
import type { ReduxState } from '../../reducers/index';
import { StandardText, StandardTextEmphasis } from '../typography/typography';
import pxToRem from '../../utilities/pxToRem';
import type { Dispatch, StyleProps } from '../../utilities/types';
import FormWithImage from '../formWithImage/formWithImage';
import BankList from '../verifyTrialDeposits/bankList';
import TryAnotherAccount from '../verifyTrialDeposits/tryAnotherAccount';
import { FAST_LINK_HEADER_TEXT } from '../openNewAccountFormFlow/chooseFunding.constants';
import type { ExternalAccountState } from '../../containers/addExternalAccount/addExternalAccount.reducers';
import Routes, { accountFundingHelpers } from '../../containers/routes/routes.constants';
import { ANALYTICS_LOAD_ACCOUNT_VERIFICATION_FAILED } from '../../analytics/actions';
import Colors from '../colors/colors';
import getImageSrc from '../../utilities/getImageSrc';
import ImagesFileNames from '../../images';
import ConfirmationModal from '../modal/ConfirmationModal';
import { ModalBtnText } from '../cms/buttonText.constants';
import { ModalText } from '../cms/blockText.constants';
import i18n from '../../strings/i18n';

const styles = {
  textSection: { marginBottom: pxToRem(30) },
  overrideCtaContainer: { marginTop: 0, marginBottom: pxToRem(10) },
  buttonContainer: { display: 'flex' },
  horizontalLine: {
    marginTop: pxToRem(40),
    marginBottom: pxToRem(40),
    height: '0.5px',
    opacity: 0.3,
    border: `solid 0.5px ${Colors.lineBreak}`,
    width: '100%',
  },
};

type OwnProps = {
  isFunding?: boolean;
};

type StateProps = {
  accountType?: string;
  accountNumber?: string;
  startedFlow?: ExternalAccountState;
};

type DispatchProps = {
  loadAnalyticsAccountVerificationFailed: () => void;
};

type Props = OwnProps & StateProps & DispatchProps & StyleProps;

const mapStateToProps = (state: ReduxState) => {
  return {
    accountType: state.addExternalAccount.accountType,
    accountNumber: state.addExternalAccount.accountNumber,
    bankName: state.addExternalAccount.bankName,
    newAccountType: state.applications.newAccount.accountType,
    startedFlow: state.addExternalAccount.startedFlow,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadAnalyticsAccountVerificationFailed: () =>
    dispatch({ type: ANALYTICS_LOAD_ACCOUNT_VERIFICATION_FAILED }),
});

export const AccountVerificationFailed = (props: Props) => {
  const {
    classes,
    accountType = '',
    accountNumber = '',
    isFunding,
    startedFlow,
    loadAnalyticsAccountVerificationFailed,
  } = props;
  useEffect(() => {
    loadAnalyticsAccountVerificationFailed();
  });
  let EXTERNAL_PATH;
  if (startedFlow?.page === 'EAO') {
    EXTERNAL_PATH = accountFundingHelpers.getEAOFundingUrl(startedFlow?.accountId);
  } else if (startedFlow?.page === 'NAO') {
    EXTERNAL_PATH = Routes.NAO_NEW_ACCOUNT_FUNDING;
  } else {
    EXTERNAL_PATH = accountFundingHelpers.getDirectFundingUrl(startedFlow?.accountId);
  }
  if (!isFunding) {
    EXTERNAL_PATH = Routes.ADD_EXTERNAL_ACCOUNT_NUM;
  }

  const [openCancelModal, setOpenCancelModal] = useState(false);

  const history = useHistory();

  // Fetching Images from Control Center's S3 Bucket
  const fundingImageUrl = getImageSrc(ImagesFileNames.accountOpeningJpg);
  const fundingImageUrlLg = getImageSrc(ImagesFileNames.accountOpening_2xJpg);
  const fundingImageUrlXl = getImageSrc(ImagesFileNames.accountOpening_3xJpg);
  const servicingImageUrl = getImageSrc(ImagesFileNames.addExternalAccountsJpg);
  const servicingImageUrlLg = getImageSrc(ImagesFileNames.addExternalAccounts_2xJpg);
  const servicingImageUrlXl = getImageSrc(ImagesFileNames.addExternalAccounts_3xJpg);

  // don't allow direct hits to this page w/o an account number
  if (!accountNumber || !accountType) {
    return <Redirect to={Routes.DASHBOARD} />;
  }
  const IMG_URL = isFunding ? fundingImageUrl : servicingImageUrl;
  const IMG_URL_LG = isFunding ? fundingImageUrlLg : servicingImageUrlLg;
  const IMG_URL_XL = isFunding ? fundingImageUrlXl : servicingImageUrlXl;
  const DATA_TRACK_TITLE = i18n({ ACCOUNT_LINKED_ERROR: 'CANCEL_AND_GO_TO_DASHBOARD' });
  const renderHeaderCopy = () => {
    return <StandardText>{i18n({ ACCOUNT_LINKED_ERROR: 'CTA_COPY' })}</StandardText>;
  };

  const redirectToExternal = () => {
    history.push(EXTERNAL_PATH);
  };

  return (
    <Grid container className={classes.parentContainer} spacing={0} alignItems="stretch">
      <FormWithImage
        imageUrl={IMG_URL}
        imageUrlLg={IMG_URL_LG}
        imageUrlXl={IMG_URL_XL}
        classes={{ ctaContainer: classes.overrideCtaContainer }}
        ctaText={i18n({ ACCOUNT_LINKED_ERROR: 'CTA_HEADER' })}
        showMarketingMessage={isFunding}
        ctaDescription={renderHeaderCopy()}
        useLoadingAnimation={false}
      >
        <Card component="div" square className={classes.newExternalAccountSubOption}>
          <CardContent
            classes={{ root: classes.cardContentOption }}
            data-test="account-yodlee-try-again"
          >
            <StandardTextEmphasis component="div" className="mt-4 mb-4">
              {FAST_LINK_HEADER_TEXT}
            </StandardTextEmphasis>
            <BankList />

            <Grid component="div" className="mt-4">
              <Button
                variant="primary"
                onClick={() => history.push(Routes.ADD_EXTERNAL_YODLEE)}
                data-test="add-external-yodlee"
              >
                {i18n({ ACCOUNT_LINKED_ERROR: 'SIGN_IN_EXTERNAL_ACCOUNT' })}
              </Button>
            </Grid>
          </CardContent>
        </Card>
        <Grid className="p-4" data-test="account-routing-try-again">
          <TryAnotherAccount onNext={redirectToExternal} />
        </Grid>

        <Grid className="mt-20">
          <Button
            variant="primary-ghost"
            data-test={`${kebabCase(
              i18n({ ACCOUNT_LINKED_ERROR: 'CANCEL_AND_GO_TO_DASHBOARD' })
            )}-btn`}
            data-track-title={DATA_TRACK_TITLE}
            onClick={() => setOpenCancelModal(true)}
          >
            {i18n({ ACCOUNT_LINKED_ERROR: 'CANCEL_AND_GO_TO_DASHBOARD' })}
          </Button>

          <ConfirmationModal
            confirmText={ModalBtnText.CANCEL_EXIT_BTN}
            contentText={
              isFunding
                ? ModalText.CANCEL_FUNDING_BODY
                : ModalText.CANCEL_ADD_NEW_EXTERNAL_ACCOUNT_BODY
            }
            data-test="non-promotional-cancel-modal"
            onConfirm={() => history.push(Routes.DASHBOARD)}
            onExit={() => setOpenCancelModal(false)}
            titleText={
              isFunding
                ? ModalText.CANCEL_FUNDING_TITLE
                : ModalText.CANCEL_ADD_NEW_EXTERNAL_ACCOUNT_TITLE
            }
            visible={openCancelModal}
          />
        </Grid>
      </FormWithImage>
    </Grid>
  );
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(AccountVerificationFailed);
const styledComponent = withStyles(styles)(connectedComponent);
export default styledComponent;
